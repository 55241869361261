import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import PremilitaComponent from "premialita-components/dist/premialita-components.common";
import 'vue3-toastify/dist/index.css';

const app = createApp(App);
app.use(router).use(PremilitaComponent);
app.mount("#app");
