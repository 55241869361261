<template>
    <div class="page-container">
        <SidebarComponent />
        <div class="page-content">
            <ItinerariesComponent baseUrl="https://ssot.laculturapremia.it/api" :token="token" :router="router" :sortOnly="false     " />
        </div>
    </div>
</template>

<script>
import SidebarComponent from '@/components/Sidebar.vue';
import ItinerariesComponent from "@laculturapremia/itineraries/dist/itineraries.common";
import {useRouter} from 'vue-router';
import { onBeforeMount, ref } from 'vue';

export default {
    name: 'HomePage',
    components: {
        SidebarComponent,
        ItinerariesComponent
    },
    setup() {
        const token = ref('');
        const getError = ref(null);
        const router = useRouter();

        const getToken = () => {
            const data = localStorage.getItem('mi_cms-tokens');
            if (data === null || data === undefined || data === '') 
            getError.value = 'There is no token'
            else {
                token.value = JSON.parse(data).access;
                getError.value = null;
            }
        }

        onBeforeMount(() => {
           getToken();
        })

        
        return {
            token,
            router
        }
    }
}
</script>

<style>
.page-container {
    display: flex;
}
.page-content {
    padding: 16px;
    width: 75%;
}
</style>