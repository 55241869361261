<template>
<div class="side-bar">
    <ul>
        <li>
            <a href="/itineraries">Itineraries</a>
        </li>
        <li>
            <a href="/activities">Activities</a>
        </li>
        <li>
            <a href="/offers">Offers</a>
        </li>
    </ul>
</div>
</template>

<script>
export default {
    name: 'SidebarComponent'
}
</script>

<style>
.side-bar {
    width: 25%;
    padding: 12px;
    border-right: 0.5px solid #D3DBF3;
}

.side-bar ul {
    display: flex;
    flex-flow: column;
    gap: 16px;
}

.side-bar ul li {
    list-style: none;
}

.side-bar ul li a {
    text-decoration: none;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #2D489D;
}
</style>
