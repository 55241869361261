import { createRouter, createWebHistory } from "vue-router";
import Itineraries from "@/pages/Itineraries.vue";
import Activities from "@/pages/Activities.vue";
import Offers from "@/pages/Offers.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/itineraries",
      name: "Itineraries",
      component: Itineraries,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/activities",
      name: "Activities",
      component: Activities,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/offers",
      name: "Offers",
      component: Offers,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/pages/Login.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let token = JSON.parse(localStorage.getItem("mi_cms-tokens"))?.access;
    if (!token) {
      next({ name: "Login" });
    } else {
      next();
    }
  }
  next();
});

export default router;
