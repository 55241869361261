<template>
<div class="page-container">
    <SidebarComponent />
    <div class="page-content">
        <OffersComponent baseUrl="https://ssot.laculturapremia.it/api" :token="token" :router="router" />
    </div>
</div>
</template>

<script>
import SidebarComponent from '@/components/Sidebar.vue';
import OffersComponent from "@laculturapremia/offer/dist/offer.common";
import {
    useRouter
} from 'vue-router';
import {
    ref,
    onBeforeMount
} from "vue";

export default {
    name: "DashboardPage",
    components: {
        SidebarComponent,
        OffersComponent
    },
    setup() {
        const token = ref('');
        const getError = ref(null);
        const router = useRouter();

        const getToken = () => {
            const data = localStorage.getItem('mi_cms-tokens');
            if (data === null || data === undefined || data === '')
                getError.value = 'There is no token'
            else {
                token.value = JSON.parse(data).access;
                getError.value = null;
            }
        }

        onBeforeMount(() => {
            getToken();
        })

        return {
            token,
            router
        }
    }
}
</script>
